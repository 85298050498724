import React from 'react';
import queryString from 'query-string';
import './App.css';

function App() {
  console.log(window.location.search);
  const parsed = queryString.parse(window.location.search);
  console.log(parsed);

  return (
    <div className="App">
      {}
      <iframe
      title="vimeo-player"
      src={`https://player.vimeo.com/video/${parsed.id}`}
      width="640"
      height="360"
      frameborder="0"
      allowfullscreen
      />
    </div>
  );
}

export default App;
